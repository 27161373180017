.user-menu {
}

.user-menu .user-menu-info {
  background-color: #ffffff;
  padding: 20px 16px 10px 16px;
}

.user-menu .user-menu-info .user-menu-info-header {
  color: #aaaaaa;
}
