.users-table th.ant-table-cell {
  background-color: #001529;
  color: #eee;
}

.users-table th.ant-table-cell:hover {
  background-color: rgba(1, 17, 31, 0.85);
}

.users-table .ant-table-filter-trigger:hover {
  color: #eee;
}

.users-table-row {
  cursor: pointer;
}

.center-icon {
  align-self: center;
  width: 100%;
}
