.header {
  padding: 0 0 0 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.header .logo {
  color: white;
  font-weight: bold;
  font-size: 1.5em;
  cursor: pointer;
}

.header .organization {
  padding-top: 2px;
  padding-left: 20px;
  color: lightgray;
  font-weight: bold;
  font-size: 0.8em;
}

.header .who {
  padding-top: 2px;
  padding-left: 20px;
  color: lightgray;
  font-weight: bold;
  font-size: medium;
}

.header .actions {
  text-align: right;
  color: #ffffff;
  font-size: 25px;
  padding-right: 16px;
}
