.page-not-found {
  text-align: center;
  padding: 50px;
}

.page-not-found h1 {
  font-weight: bold;
  font-size: 3em;
}

.page-not-found h2 {
  font-size: 2em;
}

.astronaut {
  width: 20%;
}
