.editorSubjectTitle {
  font-size: 1.25em;
}

#question-modal-form_editorSubjectLine1,
#question-modal-form_editorSubjectLine2 {
  width: 20em;
  margin-left: 2em;
}

#question-modal-form .ant-form-item.editorSubjectFormItem {
  flex-direction: row;
  align-items: center;
}

#question-modal-form .editorSubjectFormItem .ant-form-item-control-input-content {
  flex: 1;
  max-width: fit-content;
}

#question-modal-form .ant-form-item-control-input-content .ant-input-textarea-show-count::after {
  margin-left: 1em;
}

#question-modal-form .editorSubjectFormItem .ant-form-item-explain.ant-form-item-explain-error {
  margin-left: 2em;
}
