.questions-table th.ant-table-cell {
  background-color: #001529;
  color: #eee;
}

.questions-table th.ant-table-cell:hover {
  background-color: rgba(1, 17, 31, 0.85);
}

.questions-table .ant-table-filter-trigger:hover {
  color: #eee;
}

.questions-table-row {
  cursor: pointer;
}

.ant-ribbon {
  z-index: 3;
}

.ant-ribbon span {
  cursor: pointer;
}

.ant-ribbon-wrapper .ant-table-thead {
  height: 6rem;
}

th:last-child .react-resizable-handle {
  display: none;
}
