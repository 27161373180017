.footer {
  padding: 10px;
  text-align: center;
  background-color: #f5f5f5;
  -webkit-box-shadow: 0px 0px 8px -2px #000000;
  box-shadow: 0px 0px 8px -2px #000000;
}

.user-menu,
.ant-menu-vertical {
  border: 2px solid rgba(0, 21, 40, 0.25);
}

.user-menu .ant-menu-vertical {
  border: none;
}

.dashboard-title {
  font-size: 1.4rem;
  font-weight: 600;
  color: #001528;
}

.dashboard-link {
  font-size: 1.1rem;
  font-weight: 600;
  color: #001528;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  gap: 20px;
}

.dashboard-link::before {
  content: '→';
  margin-right: 10px;
}
