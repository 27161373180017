.ant-create-user-form {
  padding: 8px;
}

.ant-create-user-form .ant-form-item {
  display: flex;
}

.ant-create-user-form .ant-form-item-control-wrapper {
  flex: 1;
}

.ant-form {
  max-width: none;
}
