.message-box-holder {
  width: 100%;
  margin: 0 0 15px;
  display: flex;
  flex-flow: column;
  align-items: flex-end;
}

.message-box {
  padding: 6px 10px;
  border-radius: 6px 0 6px 0;
  position: relative;
  background: #d3f261cb;
  border: 2px solid #7cb3053f;
  color: #323131;
  font-size: 12px;
  display: flex;
  flex-direction: column;
}

.message-box::after {
  content: '';
  position: absolute;
  border: 10px solid transparent;
  border-top: 10px solid #7cb3053f;
  border-right: none;
  bottom: -22px;
  right: 10px;
}

.message-box.end-user {
  background: rgba(0, 114, 135, 0.1);
  border: 2px solid rgba(0, 114, 135, 0.1);
  align-self: flex-start;
}

.message-box.end-user::after {
  right: auto;
  bottom: auto;
  top: -22px;
  left: 9px;
  border: 10px solid transparent;
  border-bottom-color: transparent;
  border-bottom-style: solid;
  border-bottom-width: 10px;
  border-left-color: transparent;
  border-left-style: solid;
  border-left-width: 10px;
  border-bottom: 10px solid rgba(0, 114, 135, 0.2);
  border-left: none;
}

.message-sender {
  margin: 0;
  font-size: 12px;
  color: #309c5f;
  align-self: flex-end;
}

.message-sender.end-user {
  color: #30649c;
  align-self: flex-start;
}

.message-date-time {
  font-size: 0.95em;
  align-self: flex-end;
  color: #323131bd;
}

.message-date-time.end-user {
  align-self: flex-start;
}

.message-starting-date {
  color: #323131bd;
  text-align: center;
}
