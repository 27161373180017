.ReportsControlBar {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  gap: 10px;
}

.ReportsControlBar_dateRange {
  margin-right: 0px !important;
  width: 14.2rem;
}

.ReportsControlBar_inputElement {
  margin-right: 0px !important;
}

.ReportsControlBar_treeSelect {
  width: auto;
  min-width: 10rem;
  max-width: 24rem;
}

.ReportsControlBar > button {
  margin-left: auto;
}

.ReportsControlBar_reset {
  padding-left: 5px;
  padding-right: 5px;
}
