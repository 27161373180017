.RubricsTab .create-rubric-button {
  margin-bottom: 2em;
}

.SubRubricsRow {
  display: flex;
  justify-content: space-between;
  padding: 0.5em;
}

.SubRubricsRow:not(:last-child) {
  border-bottom: 1px solid #eee;
}
