.login-page {
  background-color: #001529;
}

.login-page header {
  position: absolute;
  text-align: center;
  top: 0;
  width: 100%;
  height: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 3em;
  font-weight: bold;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
}
