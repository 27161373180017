.GestionPagheControlBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  gap: 40px;
}

.GestionPagheControlBar_inputElement {
  margin-right: 0px !important;
}

.GestionPagheControlBar_treeSelect {
  width: auto;
  min-width: 16rem;
  max-width: 24rem;
}
