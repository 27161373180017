.questions-container-table th.ant-table-cell {
  background-color: #001529;
  color: #eee;
}

.questions-container-table th.ant-table-cell:hover {
  background-color: rgba(1, 17, 31, 0.85);
}

.questions-container-table .ant-table-filter-trigger:hover {
  color: #eee;
}

.questions-container-table-row {
  cursor: pointer;
}
