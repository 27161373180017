.rubrics-summary {
  width: 25em;
}

.rubrics-summary li.ant-list-item {
  flex-direction: column;
  align-items: flex-start;
}

.rubrics-summary ul {
  list-style: none;
}

.rubrics-summary-table {
  width: 100%;
}

.rubrics-summary-table-header {
  display: flex;
  flex-direction: row;
}

.rubrics-summary-table-children {
  overflow: initial;
  display: block;
  opacity: 1;
  height: auto;
}

.rubrics-summary-table-children.hidden {
  overflow: hidden;
  display: none;
  opacity: 0;
  height: 0px;
}

.rubrics-summary-table-header-name {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.rubrics-summary-table-icons {
  display: flex;
  flex-direction: row;
  margin-left: auto;
}

.rubrics-summary-table-questions-number {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eee;
  width: 1rem;
  height: 1rem;
  font-size: 0.8rem;
  text-align: center;
  border-radius: 50%;
  margin-right: 0.25rem;
}

.rubrics-summary-table-delete-button {
  cursor: pointer;
}

.collapse-child {
  display: flex;
}

.collapse-button {
  padding: 0.25em;
  border-radius: 25%;
}

.collapse-button .hide-children-icon {
  transform: rotate(-180deg);
  transition: transform 0.2s ease 0s;
  width: 1em;
  height: 1em;
}

.collapse-button .show-children-icon {
  transition: transform 0.2s ease 0s;
  width: 1em;
  height: 1em;
}

.collapse-button:hover {
  background-color: #eee;
  cursor: pointer;
}

.sort-button:hover {
  background-color: #eee;
  cursor: pointer;
}
